.closeMenuWrapper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 40px;
}

.dragBar{
  height: 5px;
  width: 40px;
  border-radius: 25px;
  background-color: rgb(200, 200, 200);
}